$(document).on('turbo:load', function() {
  $('#campaign_date').daterangepicker({
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear'
    }
  });

  $('#campaign_date').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    searchCampaigns(this);
  });

  $('#campaign_date').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('')
    picker.setStartDate({});
    picker.setEndDate({});
  });

  $('#report_campaign_date').daterangepicker({
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear'
    }
  });

  $('#report_campaign_date').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    searchCampaigns(this);
  });

  $('#report_campaign_date').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('')
    picker.setStartDate({});
    picker.setEndDate({});
  });


  $("#bulk_actions_select_all").change(function() {
    if (this.checked) {
      $("input[name='bulk_actions_ids']").prop('checked', true);
    } else {
      $("input[name='bulk_actions_ids']").prop('checked', false);
    }
    toggleApproveButton();
  });

  $("input[name='bulk_actions_ids']").change(function() {
    toggleApproveButton();
  });

  $("#campaign_status").on('change', function(){
    if ( this.value === '3' ) {
      $('.force-sync input').attr("disabled", false);
    } else {
      $('.force-sync input').attr("disabled", true);
      $('.force-sync input').prop("checked", false);
    }
  });

  var textBox = document.getElementById("query");
  if (textBox !== null) {
    textBox.addEventListener("keyup", function (event) {
      if (event.keyCode == 13) {
        document.getElementById("search_button").click();
      }
    });
  }
});

function ApproveCampaigns(e, status, team_id, object='Campaign') {
  e.preventDefault();
  let ids = checkedCheckboxIds();
  let name = ids.length == 1 ? object : object+'s';

  let html = ''
  let message = ''
  let connection = $('#connection').val();
  let post_message = name+'?'

  if (status === 'Approved') {
    if (connection.includes('Marketing')) {
      post_message = 'and Sync '+name+' to above Connected Marketing cloud?'
    } else if (connection.includes('Salesforce')){
      post_message = 'and Sync '+name+' to above Connected Salesforce cloud?'
    }
    let above_message = 'Are you sure, you want to Approve ' + post_message

    message = 'Are you sure, you want to Approve ' + post_message

    let mc_name = $('#modal-title').attr('mc-cloud-name');
    let mc_option =  '<input type="radio" id="html" name="fav_language" value="" checked=true> <strong class="text-sm text-red-500"> '+ mc_name +'</strong><br>'
    if (mc_name.length === 0) {
      html = message;
    } else {
      html = mc_option + above_message;
    }
  } else {
    message = 'Are you sure, you want `'+status+'` '+name+'?'
  }

  $('#modal-title').html(message);
  $('#cancel-btn-popup').show();
  $('#confirm-btn-popup').text("Yes, I'm sure");
  $('#confirm-btn-popup').attr('onclick', 'acceptApprove('+team_id+', "'+status+'", "'+object+'")');
  $('#cancel-btn-popup').attr('onclick', 'cancelApprove()');
}

function acceptApprove(team_id, status, object) {
  let ids = checkedCheckboxIds();
  $.ajax({
    type: 'POST',
    url: '/account/teams/'+team_id+'/'+object.toLowerCase()+'s/approve',
    data: { ids: ids, status: status},
    dataType: 'json',
    error: function(xhr) {
      console.log(xhr)
    },
    complete: function(response) {
      $('#modal-title').html(response.responseJSON.notice);
       showPopupModel();

      $('#cancel-btn-popup').hide();
      $('#confirm-btn-popup').text('Ok');
      $('#confirm-btn-popup').attr('onclick', 'location.reload();');
      $('#popup-close-button').attr('onclick', 'location.reload();');
    }
  });
}

function deleteItem (path, txt, redirect_url=null) {
  $('#modal-title').html(txt);
  $('#confirm-btn-popup').text("Yes, I'm sure");
  $('#confirm-btn-popup').attr('onclick', 'removeItemFromBe("'+path+'", "'+redirect_url+'");');
}

function removeItemFromBe(path, redirect_url) {
  $.ajax({
    type: 'DELETE',
    url: path,
    dataType: 'json',
    error: function(xhr) {
      console.log(xhr)
    },
    complete: function(response) {
      if (redirect_url === 'null') {
        location.reload();
      } else {
        window.location.href = redirect_url;
      }
    }
  });
}

function showPopupModel() {
  popupModal = document.getElementById("popup-modal");
  const $modal = new Modal(popupModal);
  $modal.show();
}

function hidePopupModel() {
  popupModal = document.getElementById("popup-modal");
  const $modal = new Modal(popupModal);
  $modal.hide();
}

function cancelApprove() {
  $("#bulk_actions_select_all").prop('checked', false);

  $("input:checkbox[name=bulk_actions_ids]:checked").each(function() {
    $(this).prop('checked', false);
  });

  toggleApproveButton();
}

function searchCampaigns(_this) {
  let data = {};
  let url = '?';

  $('.search-box').each(function(index, item) {
    $.extend(data, { [item.name]: item.value });

    if (item.value.length !== 0) {
      url += `search[${item.name}]`+'='+item.value+'&'
    }
  });

  $('#search_button').attr('href', url);
}

function toggleApproveButton() {
  if (checkedCheckboxIds().length === 0) {
    $('#approve_button').hide();
    $('#select_text').show();
  } else {
    $('#approve_button').show();
    $('#select_text').hide();
  }
}

function checkedCheckboxIds() {
  let ids = [];

  $("input:checkbox[name=bulk_actions_ids]:checked").each(function(){
    ids.push($(this).val());
  });

  return ids;
}

function clearSearch() {
  $('.search-box').each(function(index, item) {
    item.value = null;
    $('#search_button').attr('href', '');
  });
}

function toggleActionDropdown(id) {
  $('#'+id).toggle();
}

function alertMessage(c_type_counts) {
  if (c_type_counts == 0) {
    alert('You need to create Campaign Types first before creating New Campaign..!')
  }
}

window.toggleActionDropdown = toggleActionDropdown;
window.clearSearch = clearSearch;
window.checkedCheckboxIds = checkedCheckboxIds;
window.toggleApproveButton = toggleApproveButton;
window.searchCampaigns = searchCampaigns;
window.ApproveCampaigns = ApproveCampaigns;
window.acceptApprove = acceptApprove;
window.cancelApprove = cancelApprove;
window.deleteItem = deleteItem;
window.removeItemFromBe = removeItemFromBe;
window.alertMessage = alertMessage;

