// dependent_form_fields_controller.js
import { Controller } from "@hotwired/stimulus"
var prevSegmentsHistory = [];

export default class extends Controller {
  static targets = [ "dependentField" ]

  updateDependentFields(event) {
    const originalSelect2Event = event.detail.event;
    console.log(`catching event ${event.type}`, originalSelect2Event);
    var selctedValues = [];

    // Collect selected segments.
    $( originalSelect2Event.target.options ).each(function( index, option ) {
      if (option.selected == true) {
        selctedValues.push(option.value);
      }
    });

    // Reset segment options when remove selected segment.
    if ( prevSegmentsHistory.length >= selctedValues.length ){
      $("#campaign_segment_option_ids").val(null).trigger('change');
    }

    // Refresh segment history after check.
    prevSegmentsHistory = selctedValues;

    // Enable/disable segment options based on segment selections.
    this.dependentFieldTargets.forEach((dependentField) => {
      $( dependentField.options ).each(function( index, option ) {
        if (selctedValues.includes(option.getAttribute('data-segment-id'))) {
          option.disabled = false;
        } else {
          option.disabled = true;
        }
      });
    });
  }
}